"use client";

import { ThemeToggle } from "@/components/theme-toggle";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";
import { Button } from "./ui/button";

export default function Header() {
  const pathname = usePathname();

  if (pathname.startsWith("/articles")) {
    return null;
  }

  return (
    <header className="sticky top-0 z-50 w-full border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/5">
      <div className="container m-auto flex h-20 max-w-7xl items-center px-4">
        <div className="mr-4 hidden md:flex">
          <a className="mr-4 flex items-center space-x-2 lg:mr-6" href="/">
            <svg
              viewBox="0 0 24 24"
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 5C22 3.34315 20.6569 2 19 2H12C10.3431 2 9 3.34315 9 5V9C9 10.6569 7.65685 12 6 12H5C3.34315 12 2 13.3431 2 15V19C2 20.6569 3.34315 22 5 22H9C10.6569 22 12 20.6569 12 19V18C12 16.3431 13.3431 15 15 15H19C20.6569 15 22 13.6569 22 12V5Z"
                fill="#1488CC"
              />
            </svg>
            <span className="hidden font-bold lg:inline-block">
              CSS Grid Maker
            </span>
          </a>
        </div>
        <nav className="px-3">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="link"
                size="icon"
                className="text-foreground/60 transition-colors hover:text-foreground/80"
              >
                Tools
                <span className="sr-only">Toggle theme</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>
                <Link href="/grid-maker">Grid Maker</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href="/color-palette">Color Palette</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href="/palette-viewer">Palette Viewer</Link>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Link href="/type-scale">type-scale</Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </nav>
        <Button
          variant="link"
          size="sm"
          asChild
          className="text-foreground/60 text-sm transition-colors hover:text-foreground/80"
        >
          <Link href="/articles">Diary</Link>
        </Button>
        <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
          <nav className="flex items-center">
            <ThemeToggle />
          </nav>
        </div>
      </div>
    </header>
  );
}
