import { APCAcontrast, sRGBtoY } from "apca-w3";
import chroma, { hex } from "chroma-js";
import { type ClassValue, clsx } from "clsx";
import { useCallback } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function hslToRgb(
  h: number,
  s: number,
  l: number,
): [number, number, number] {
  const sNormalized = s / 100;
  const lNormalized = l / 100;
  const k = (n: number) => (n + h / 30) % 12;
  const a = sNormalized * Math.min(lNormalized, 1 - lNormalized);
  const f = (n: number) =>
    lNormalized - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return [
    Math.round(255 * f(0)),
    Math.round(255 * f(8)),
    Math.round(255 * f(4)),
  ];
}

export function rgbToHex(r: number, g: number, b: number): string {
  return `#${[r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join("")}`;
}

export function hexToRgb(hex: string): [number, number, number] {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        Number.parseInt(result[1], 16),
        Number.parseInt(result[2], 16),
        Number.parseInt(result[3], 16),
      ]
    : [0, 0, 0];
}

export function getLuminance(r: number, g: number, b: number): number {
  const normalize = (v: number) => {
    const normalized = v / 255;
    return normalized <= 0.03928
      ? normalized / 12.92
      : ((normalized + 0.055) / 1.055) ** 2.4;
  };

  const a = [r, g, b].map(normalize);
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function getContrastRatio(color1: string, color2: string): number {
  const lum1 = getLuminance(...hexToRgb(color1));
  const lum2 = getLuminance(...hexToRgb(color2));
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export function generatePagination(currentPage: number, totalPages: number) {
  // If the total number of pages is 7 or less,
  // display all pages without any ellipsis.
  if (totalPages <= 7) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  // If the current page is among the first 3 pages,
  // show the first 3, an ellipsis, and the last 2 pages.
  if (currentPage <= 3) {
    return [1, 2, 3, "...", totalPages - 1, totalPages];
  }

  // If the current page is among the last 3 pages,
  // show the first 2, an ellipsis, and the last 3 pages.
  if (currentPage >= totalPages - 2) {
    return [1, 2, "...", totalPages - 2, totalPages - 1, totalPages];
  }

  // If the current page is somewhere in the middle,
  // show the first page, an ellipsis, the current page and its neighbors,
  // another ellipsis, and the last page.
  return [
    1,
    "...",
    currentPage - 1,
    currentPage,
    currentPage + 1,
    "...",
    totalPages,
  ];
}

export const getWcagContrast = (
  backgroundHex: string,
  textHex: string,
): number => chroma.contrast(backgroundHex, textHex);

export const apcaContrast = (backgroundHex: string, textHex: string): number =>
  Math.round(
    Math.abs(
      +APCAcontrast(
        sRGBtoY(chroma(textHex).rgb()),
        sRGBtoY(chroma(backgroundHex).rgb()),
      ),
    ),
  );

export const lchToRgb = (
  l: number,
  c: number,
  h: number,
): [number, number, number] => {
  const a = c * Math.cos((h * Math.PI) / 180);
  const b = c * Math.sin((h * Math.PI) / 180);
  const y = (l + 16) / 116;
  const x = a / 500 + y;
  const z = y - b / 200;

  const r = 3.2404542 * x - 1.5371385 * y - 0.4985314 * z;
  const g = -0.969266 * x + 1.8760108 * y + 0.041556 * z;
  const b1 = 0.0556434 * x - 0.2040259 * y + 1.0572252 * z;

  return [
    Math.round(
      255 * (r > 0.0031308 ? 1.055 * r ** (1 / 2.4) - 0.055 : 12.92 * r),
    ),
    Math.round(
      255 * (g > 0.0031308 ? 1.055 * g ** (1 / 2.4) - 0.055 : 12.92 * g),
    ),
    Math.round(
      255 * (b1 > 0.0031308 ? 1.055 * b1 ** (1 / 2.4) - 0.055 : 12.92 * b1),
    ),
  ];
};

export const isValidRgb = (r: number, g: number, b: number): boolean => {
  return r >= 0 && r <= 255 && g >= 0 && g <= 255 && b >= 0 && b <= 255;
};

export const lchToHex = (l: number, c: number, h: number): string => {
  return chroma.lch(l, c, h).hex();
};

export const hexToLch = (hex: string): { l: number; c: number; h: number } => {
  const [l, c, h] = chroma(hex).lch();
  return { l, c, h };
};

export const getTextColor = (hexColor: string): string => {
  const r = Number.parseInt(hexColor.slice(1, 3), 16);
  const g = Number.parseInt(hexColor.slice(3, 5), 16);
  const b = Number.parseInt(hexColor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? "#000000" : "#ffffff";
};
